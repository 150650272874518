@tailwind base;
@tailwind components;
@tailwind utilities;

[x-cloak] {
  display: none !important;
}

/* Set the Fonts for Title and Paragraph/Link elements.*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sue Ellen Francisco", cursive;
}

p,
a,
button,
input,
label,
ul,
ol,
figcaption {
  font-family: "Special Elite", cursive;
}

.text-0 {
  font-size: 0.25em;
}

.custom-gradient-bg {
  @apply from-fuchsia-300 to-pink-400 dark:from-pink-700 dark:to-fuchsia-800;
  /* @apply dark:from-zebra-green dark:to-zebra-blue from-zebra-orange to-zebra-deep-orange; */
}

.link-desktop {
  @apply dark:hover:bg-slate-900 hover:bg-pink-400 inline-block px-4 lg:px-3 pt-4 pb-3 transition-all duration-200 rounded-lg;
}

.link-mobile {
  @apply dark:hover:bg-indigo-900 hover:bg-blue-500 block px-5 py-3 transition-all duration-200;
}
